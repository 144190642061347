







import { Component, Prop, Vue } from "vue-property-decorator";
import Viewer from "@/components/profile/Viewer.vue";
import DocumentService from "@/services/DocumentService";
import { processError } from "@/utils/ComponentUtils";
import { namespace } from "vuex-class";
import InsuranceDTO from "@/dto/archive/insurance/InsuranceDTO";
import InsuranceFilter from "@/dto/archive/insurance/InsuranceFilter";
import InsuranceSettingsDTO from "@/dto/archive/insurance/InsuranceSettingsDTO";
import { WorkspaceTypePathElement } from "@/dto/auth/Workspace";

const AppModule = namespace("App");
const Insurance = namespace("Insurance");

@Component({
  components: { Viewer },
})
export default class InsuranceMain extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Insurance.Mutation
  private setSettings!: (newSettings: InsuranceSettingsDTO) => void;

  @Prop({ default: null })
  private wsId!: string;

  @Prop({ default: null })
  private wsType!: string;

  filter = new InsuranceFilter();
  latestInsurances: Array<InsuranceDTO> | null = null;
  private resolveShow = false;

  public mounted(): void {
    this.filter.companyId = Number(this.wsId);
    this.filter.isLatest = true;
    if (this.wsType === WorkspaceTypePathElement.COMPANY ) {
      this.loadInsuranceSettings();
      this.loadInsurances();
    } else {
      this.resolveShow = true;
    }
  }

  loadInsurances() {
    this.startLoading();
    DocumentService.getInsurances(this.filter).then(
      ok => {
        this.latestInsurances = ok.data;
        this.stopLoading();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  loadInsuranceSettings(): void {
    this.startLoading();
    DocumentService.getInsuranceSettings(Number(this.wsId)).then(
      resp => {
        this.setSettings(resp.data);
        this.resolveShow = true;
        this.stopLoading();
      },
      error => {
        processError(error, this);
        this.stopLoading();
      }
    )
  }

}
