import { InsuranceType } from "@/dto/archive/insurance/InsuranceType";

export default class InsuranceFilter {

    public type: InsuranceType | null = null;

    public companyId!: number;

    public isLatest: boolean | null = null;

}
